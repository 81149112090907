import React from "react";
import Page from "~/templates/Page";

const data = {
  page: {
    metadata: {
      title: "Contact PT Blink",
      description: {
        internal: {
          content:
            "For general enquires please complete the contact form below. ",
        },
      },
      image: null,
    },
    sections: [
      {
        __typename: "ContentfulTextBanner",
        heading: "Contact Us",
        subHeading: null,
        buttonText: null,
        buttonLink: null,
        content: {
          raw: '{"nodeType":"document","data":{},"content":[{"nodeType":"paragraph","content":[{"nodeType":"text","value":"For general enquires please complete the form below. Alternatively, register your specific interest by clicking on the appropriate link below.​","marks":[],"data":{}}],"data":{}}]}',
        },
        id: "00f991cd-7b36-545f-989a-d3c0ad6304dc",
      },
      {
        __typename: "ContentfulContactForm",
        id: "6b0bd97d-39c2-590e-9723-78882f903abf",
        cta1Heading: "Property developers & property owners",
        cta1Text: "Book a meeting",
        cta1Link: "/book-consultation/",
        cta2Heading: "Designers, manufacturers, integrators (builders)",
        cta2Text: "Register to join",
        cta2Link: "/register/",
        portalId: "3435120",
        formId: "88cc8d4a-eabe-453d-a765-d6f8840fe802",
        successPage: {
          slug: "contact-success",
        },
      },
    ],
  },
};

function Contact() {
  return <Page data={data} />;
}

export default Contact;
